import { isNil } from 'lodash-es';
import { LinkData } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { ExportCellValue } from '../shared/components/table/table/table.model';
import { Cijferkolomtype, ResultaatBijzonderheid } from '@cumlaude/metadata';

export type CijferData = {
	cf_nm_kolomtype: string;
	clc_abb_label_avg: string | null;
	clc_is_voldoende_avg: number | null;
	cf_is_voldoende: number | null;
	cf_nr_cijfer: number | null;
	cf_nm_bijzonderheid: string | null;
	cf_nr_decimalen: number | null;
};

export type CijferlijstData = CijferData & {
	linkData: Partial<LinkData>;
	cf_nr_tekortpunten: number;
	count_records: number;
};

function getLabelClass(
	clc_is_voldoende_avg: number | null,
	cf_is_voldoende: number | null,
	bijzonderheidLabel: string | null,
	clc_abb_label_avg: string | null
) {
	if (clc_is_voldoende_avg === 0) return 'cijfer-onvoldoende';
	if (clc_is_voldoende_avg === 1) return 'cijfer-voldoende';

	if (isNil(bijzonderheidLabel) && clc_abb_label_avg !== null) {
		if (cf_is_voldoende === 0) return 'cijfer-onvoldoende';
		if (cf_is_voldoende === 1) return 'cijfer-voldoende';
	}

	return undefined;
}

export function getCijferClass(data: CijferData | null) {
	if (data === null) return 'empty';
	const { cf_nr_cijfer, clc_is_voldoende_avg, cf_nm_bijzonderheid, cf_nm_kolomtype, cf_is_voldoende, clc_abb_label_avg } = data;
	const classes = [];

	if (cf_nr_cijfer === null && clc_abb_label_avg === null && cf_nm_kolomtype !== Cijferkolomtype.ADVIES) classes.push('cijfer-null');
	if (cf_nm_bijzonderheid) classes.push('cijfer-' + generateCssClassForString(cf_nm_bijzonderheid));
	if (cf_nm_kolomtype) classes.push('cijfer-type-' + generateCssClassForString(cf_nm_kolomtype));

	const bijzonderheidLabel = getBijzonderheidLabel(cf_nm_bijzonderheid, cf_nr_cijfer, clc_abb_label_avg);
	const labelClass = getLabelClass(clc_is_voldoende_avg, cf_is_voldoende, bijzonderheidLabel, clc_abb_label_avg);
	if (labelClass) classes.push(labelClass);

	const afgerond = Math.round(Math.min(cf_nr_cijfer ?? 0, 10));
	if (isNil(labelClass) && cf_nr_cijfer !== null)
		classes.push(`cijfer-${afgerond <= 3 ? '0-3' : afgerond}${cf_nr_cijfer < 5.5 || cf_nr_cijfer >= 8 ? '-x' : ''}`);

	return classes.join(' ');
}

export function getCijferOfLabel(data: CijferData | null): string {
	if (data === null) return ' ';
	const bijzonderheidLabel = getBijzonderheidLabel(data.cf_nm_bijzonderheid, data.cf_nr_cijfer, data.clc_abb_label_avg);
	return bijzonderheidLabel ?? data.clc_abb_label_avg ?? formatDecimal(data.cf_nr_cijfer, `1.${data.cf_nr_decimalen}-${data.cf_nr_decimalen}`);
}

export function getExportValue(data: CijferData | null): ExportCellValue | undefined {
	if (!data) return undefined;
	const label = getBijzonderheidLabel(data.cf_nm_bijzonderheid, data.cf_nr_cijfer, data.clc_abb_label_avg) ?? data.clc_abb_label_avg;
	if (label) return { type: 'string', value: label };
	const format = `1.${data.cf_nr_decimalen ?? 1}-${data.cf_nr_decimalen ?? 1}`;
	return { type: 'number', value: data.cf_nr_cijfer, format };
}

export function getBijzonderheidLabel(cf_nm_bijzonderheid: string | null, cf_nr_cijfer: number | null, clc_abb_label_avg: string | null) {
	switch (cf_nm_bijzonderheid) {
		case ResultaatBijzonderheid.VRIJSTELLING:
			return 'vr';
		case ResultaatBijzonderheid.TELT_NIET_MEE:
			return 'X';
		default:
			if (!clc_abb_label_avg && !cf_nr_cijfer) return cf_nm_bijzonderheid === ResultaatBijzonderheid.NIET_GEMAAKT ? '*' : '-';
			return null;
	}
}
